/* Viewport Sizes */
/* Rotation */
/* COLORS */
/* FONTS */
/* Font Sizes for Hotels */
/* Font Colors for Hotels */
/* Font weight */
/* mixin that let you change placeholder font color. */
.payment-expiry-notice p,
.payment-expiry-notice .sasta-timer {
  display: inline;
  font-weight: bold; }
  @media screen and (max-width: 767px) {
    .payment-expiry-notice p,
    .payment-expiry-notice .sasta-timer {
      font-weight: normal; } }

.payment-expiry-notice.expired p,
.payment-expiry-notice.expired .sasta-timer {
  color: #626473;
  font-weight: normal;
  text-transform: uppercase; }
