/* reset */
/* COLORS */
/* FONTS */
/* Font Sizes for Hotels */
/* Font Colors for Hotels */
/* Font weight */
/* mixin that let you change placeholder font color. */
/* Viewport Sizes */
/* Rotation */
/* COLORS */
/* FONTS */
/* Font Sizes for Hotels */
/* Font Colors for Hotels */
/* Font weight */
/* mixin that let you change placeholder font color. */
body {
  margin: 0;
  background: #f6f6f6; }

.wrapper {
  color: #000000;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 1.5;
  overflow: hidden;
  position: relative; }
  .wrapper .promo-deal-header {
    margin: 0 auto !important;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99999999;
    background-color: #440e7b;
    padding-top: 0 !important; }
    .wrapper .promo-deal-header .promo-deal-body {
      display: block;
      margin: 0 auto;
      position: relative;
      width: 100%; }
      .wrapper .promo-deal-header .promo-deal-body .promo-deal-link {
        display: block;
        width: 100%;
        height: auto; }
        .wrapper .promo-deal-header .promo-deal-body .promo-deal-link .image {
          width: 100%;
          height: auto; }
  @media screen and (max-width: 767px) {
    .wrapper {
      overflow-y: scroll; } }
  .wrapper * {
    box-sizing: border-box; }

/* for dsm tiny pop  */
.react-tiny-popover-container {
  z-index: 1111; }

/* Viewport Sizes */
/* Rotation */
/* COLORS */
/* FONTS */
/* Font Sizes for Hotels */
/* Font Colors for Hotels */
/* Font weight */
/* mixin that let you change placeholder font color. */
/* genric styles */
.container {
  margin: 0 auto;
  padding: 0 10px; }
  @media screen and (min-width: 768px) {
    .container {
      max-width: 740px;
      padding: 0; } }
  @media screen and (min-width: 1010px) {
    .container {
      max-width: 990px; } }
  @media screen and (min-width: 1180px) {
    .container {
      max-width: 1170px; } }
  .container:after {
    clear: both;
    content: '';
    display: block; }
  .container .hide {
    display: none; }

.flight-panel {
  margin: 0 0 20px; }
  @media screen and (max-width: 767px) {
    .flight-panel {
      margin: 0; } }
  .flight-panel h2 {
    color: #000000;
    font: 24px/30px "Montserrat", sans-serif;
    margin: 0 0 15px; }
    @media screen and (max-width: 767px) {
      .flight-panel h2 {
        font-size: 14px;
        line-height: 20px;
        margin: 0 0 8px; } }
  .flight-panel .content-panel {
    background: white;
    border: 1px solid #d0d2d9;
    box-shadow: 3px 3px 4px rgba(3, 3, 3, 0.05); }
  .flight-panel .header-panel {
    border-bottom: 1px solid #d0d2d9;
    padding: 20px; }
    @media screen and (max-width: 767px) {
      .flight-panel .header-panel {
        padding: 10px; } }
  .flight-panel h3 {
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    margin: 0; }
    @media screen and (max-width: 767px) {
      .flight-panel h3 {
        font-size: 12px;
        line-height: 20px; } }
  .flight-panel .link-text {
    color: #006aac;
    float: right;
    font-weight: 500;
    margin: 0 0 0 10px;
    text-decoration: none; }
    @media screen and (max-width: 767px) {
      .flight-panel .link-text {
        font-size: 10px;
        line-height: 20px; } }
  .flight-panel .content-area {
    padding: 20px 20px 5px; }
    @media screen and (max-width: 767px) {
      .flight-panel .content-area {
        padding: 15px 10px 5px; } }

.sasta-form label,
.sasta-form .label-form,
.sasta-form .label,
.profile-from label,
.profile-from .label-form,
.profile-from .label {
  color: #000000;
  display: inline-block;
  font-size: 15px;
  font-weight: 300;
  line-height: 20px;
  margin: 0 0 12px;
  vertical-align: top; }
  @media screen and (max-width: 767px) {
    .sasta-form label,
    .sasta-form .label-form,
    .sasta-form .label,
    .profile-from label,
    .profile-from .label-form,
    .profile-from .label {
      font-size: 11px;
      line-height: 15px;
      margin: 0 0 8px; } }
  .sasta-form label .asterick,
  .sasta-form .label-form .asterick,
  .sasta-form .label .asterick,
  .profile-from label .asterick,
  .profile-from .label-form .asterick,
  .profile-from .label .asterick {
    display: none; }
  .sasta-form label .required,
  .sasta-form .label-form .required,
  .sasta-form .label .required,
  .profile-from label .required,
  .profile-from .label-form .required,
  .profile-from .label .required {
    color: red;
    display: inline; }
  .sasta-form label .required-message,
  .sasta-form .label-form .required-message,
  .sasta-form .label .required-message,
  .profile-from label .required-message,
  .profile-from .label-form .required-message,
  .profile-from .label .required-message {
    color: red;
    display: none;
    font-size: 12px;
    padding-left: 5px; }
  .sasta-form label.error .asterick,
  .sasta-form .label-form.error .asterick,
  .sasta-form .label.error .asterick,
  .profile-from label.error .asterick,
  .profile-from .label-form.error .asterick,
  .profile-from .label.error .asterick {
    display: none; }
  .sasta-form label.error .required-message,
  .sasta-form .label-form.error .required-message,
  .sasta-form .label.error .required-message,
  .profile-from label.error .required-message,
  .profile-from .label-form.error .required-message,
  .profile-from .label.error .required-message {
    display: inline; }

.sasta-form label.custom-checkbox,
.profile-from label.custom-checkbox {
  padding: 0 0 0 30px;
  position: relative; }
  .sasta-form label.custom-checkbox .label-text,
  .profile-from label.custom-checkbox .label-text {
    line-height: 18px; }
  .sasta-form label.custom-checkbox input[type='checkbox'],
  .profile-from label.custom-checkbox input[type='checkbox'] {
    left: -9999px;
    position: fixed;
    top: -9999px; }
    .sasta-form label.custom-checkbox input[type='checkbox']:checked ~ .fake-checkbox:after,
    .sasta-form label.custom-checkbox input[type='checkbox'] :checked + .fake-checkbox:after,
    .profile-from label.custom-checkbox input[type='checkbox']:checked ~ .fake-checkbox:after,
    .profile-from label.custom-checkbox input[type='checkbox'] :checked + .fake-checkbox:after {
      display: block; }
  .sasta-form label.custom-checkbox .fake-checkbox,
  .profile-from label.custom-checkbox .fake-checkbox {
    background: white;
    border: 2px solid #d0d2d9;
    height: 18px;
    left: 0;
    position: absolute;
    top: 0;
    width: 18px; }
    .sasta-form label.custom-checkbox .fake-checkbox:after,
    .profile-from label.custom-checkbox .fake-checkbox:after {
      color: #005a93;
      content: '\e903';
      display: none;
      font: 14px/16px icomoon;
      left: 2px;
      position: absolute;
      top: -4px; }

.sasta-form .field-error,
.profile-from .field-error {
  margin: -15px 0 0; }
  @media screen and (max-width: 767px) {
    .sasta-form .field-error,
    .profile-from .field-error {
      margin: -2px 0 0; } }

.sasta-form .error input[type='text'],
.sasta-form .error input[type='email'],
.sasta-form .error input[type='tel'],
.sasta-form .error input[type='password'],
.sasta-form .error .react-select__control,
.profile-from .error input[type='text'],
.profile-from .error input[type='email'],
.profile-from .error input[type='tel'],
.profile-from .error input[type='password'],
.profile-from .error .react-select__control {
  border-color: red; }

.sasta-form .mask-input-container .error input,
.profile-from .mask-input-container .error input {
  border-color: red; }

.sasta-form .form-row:after,
.profile-from .form-row:after {
  clear: both;
  content: '';
  display: block; }

.sasta-form .form-row.two-cols,
.profile-from .form-row.two-cols {
  margin: 0 -10px; }
  @media screen and (max-width: 767px) {
    .sasta-form .form-row.two-cols,
    .profile-from .form-row.two-cols {
      margin: 0; } }
  .sasta-form .form-row.two-cols .col,
  .profile-from .form-row.two-cols .col {
    float: left;
    padding: 0 10px;
    width: 50%; }
    @media screen and (max-width: 767px) {
      .sasta-form .form-row.two-cols .col,
      .profile-from .form-row.two-cols .col {
        float: none;
        padding: 0;
        width: 100%; } }

.sasta-form .input-holder,
.profile-from .input-holder {
  position: relative; }
  .sasta-form .input-holder:after,
  .profile-from .input-holder:after {
    clear: both;
    content: '';
    display: block; }
  .sasta-form .input-holder.half,
  .profile-from .input-holder.half {
    width: calc(50% - 10px); }
    @media screen and (max-width: 767px) {
      .sasta-form .input-holder.half,
      .profile-from .input-holder.half {
        width: 100%; } }
  .sasta-form .input-holder.select,
  .profile-from .input-holder.select {
    max-width: 255px; }
    @media screen and (max-width: 767px) {
      .sasta-form .input-holder.select,
      .profile-from .input-holder.select {
        max-width: 100%; } }
  .sasta-form .input-holder.tel,
  .profile-from .input-holder.tel {
    position: relative; }
    .sasta-form .input-holder.tel input[type='tel'],
    .profile-from .input-holder.tel input[type='tel'] {
      border-radius: 0;
      padding-left: 55px;
      margin: 0 0 25px !important; }
      @media screen and (max-width: 767px) {
        .sasta-form .input-holder.tel input[type='tel'],
        .profile-from .input-holder.tel input[type='tel'] {
          margin: 0 0 10px !important;
          padding-left: 51px; } }

.sasta-form input[type='text'],
.sasta-form input[type='email'],
.sasta-form input[type='password'],
.sasta-form input[type='tel'],
.sasta-form .mask-input-container input,
.profile-from input[type='text'],
.profile-from input[type='email'],
.profile-from input[type='password'],
.profile-from input[type='tel'],
.profile-from .mask-input-container input {
  background: white;
  border: 1px solid #d0d2d9;
  box-shadow: inset 3px 4px 5px rgba(0, 0, 0, 0.12);
  font-size: 15px;
  font-weight: lighter;
  height: 50px;
  line-height: 20px;
  margin: 0 0 25px;
  outline: none;
  padding: 15px;
  transition: border 0.25s linear;
  width: 100%; }
  .sasta-form input[type='text']::placeholder,
  .sasta-form input[type='email']::placeholder,
  .sasta-form input[type='password']::placeholder,
  .sasta-form input[type='tel']::placeholder,
  .sasta-form .mask-input-container input::placeholder,
  .profile-from input[type='text']::placeholder,
  .profile-from input[type='email']::placeholder,
  .profile-from input[type='password']::placeholder,
  .profile-from input[type='tel']::placeholder,
  .profile-from .mask-input-container input::placeholder {
    color: #d0d2d9; }
  @media screen and (max-width: 767px) {
    .sasta-form input[type='text'],
    .sasta-form input[type='email'],
    .sasta-form input[type='password'],
    .sasta-form input[type='tel'],
    .sasta-form .mask-input-container input,
    .profile-from input[type='text'],
    .profile-from input[type='email'],
    .profile-from input[type='password'],
    .profile-from input[type='tel'],
    .profile-from .mask-input-container input {
      font-size: 10px;
      line-height: 16px;
      margin: 0 0 10px;
      height: 30px;
      padding: 7px 10px; } }
  .sasta-form input[type='text']:focus,
  .sasta-form input[type='email']:focus,
  .sasta-form input[type='password']:focus,
  .sasta-form input[type='tel']:focus,
  .sasta-form .mask-input-container input:focus,
  .profile-from input[type='text']:focus,
  .profile-from input[type='email']:focus,
  .profile-from input[type='password']:focus,
  .profile-from input[type='tel']:focus,
  .profile-from .mask-input-container input:focus {
    border-color: #006aac !important; }
  .sasta-form input[type='text'].error,
  .sasta-form input[type='email'].error,
  .sasta-form input[type='password'].error,
  .sasta-form input[type='tel'].error,
  .sasta-form .mask-input-container input.error,
  .profile-from input[type='text'].error,
  .profile-from input[type='email'].error,
  .profile-from input[type='password'].error,
  .profile-from input[type='tel'].error,
  .profile-from .mask-input-container input.error {
    border-color: red; }

.sasta-form .rrui__select,
.profile-from .rrui__select {
  height: 50px; }

.sasta-form .hint-message,
.profile-from .hint-message {
  color: rgba(0, 0, 0, 0.6);
  display: block;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  margin: -15px 0 20px; }
  @media screen and (max-width: 767px) {
    .sasta-form .hint-message,
    .profile-from .hint-message {
      font-size: 10px;
      line-height: 14px;
      margin: -5px 0 10px; } }

.sasta-form .dropdown-error .react-select__control,
.profile-from .dropdown-error .react-select__control {
  border-color: red; }

.sasta-form .Select.sasta-dropdrown,
.profile-from .Select.sasta-dropdrown {
  margin: 0 0 25px; }
  @media screen and (max-width: 767px) {
    .sasta-form .Select.sasta-dropdrown,
    .profile-from .Select.sasta-dropdrown {
      margin: 0 0 10px; } }

.sasta-form .react-select__control,
.profile-from .react-select__control {
  margin: 0;
  transition: border 0.25s linear; }

.sasta-form .is-focused,
.sasta-form .select.is-focused:not(.is-open),
.profile-from .is-focused,
.profile-from .select.is-focused:not(.is-open) {
  z-index: 9; }
  .sasta-form .is-focused .react-select__control,
  .sasta-form .select.is-focused:not(.is-open) .react-select__control,
  .profile-from .is-focused .react-select__control,
  .profile-from .select.is-focused:not(.is-open) .react-select__control {
    border-color: #006aac !important; }

.sasta-form.profile-from .field-error,
.profile-from.profile-from .field-error {
  margin: 0; }

.sasta-form.profile-from .content-area,
.profile-from.profile-from .content-area {
  padding: 20px 20px 10px; }
  @media screen and (max-width: 767px) {
    .sasta-form.profile-from .content-area,
    .profile-from.profile-from .content-area {
      padding: 15px 10px 5px; } }

.sasta-form.profile-from label,
.sasta-form.profile-from .label-text,
.sasta-form.profile-from .label-form,
.profile-from.profile-from label,
.profile-from.profile-from .label-text,
.profile-from.profile-from .label-form {
  font-size: 14px;
  margin: 0 0 5px; }
  @media screen and (max-width: 767px) {
    .sasta-form.profile-from label,
    .sasta-form.profile-from .label-text,
    .sasta-form.profile-from .label-form,
    .profile-from.profile-from label,
    .profile-from.profile-from .label-text,
    .profile-from.profile-from .label-form {
      font-size: 12px; } }

.sasta-form.profile-from input[type='text'],
.sasta-form.profile-from input[type='email'],
.sasta-form.profile-from input[type='password'],
.sasta-form.profile-from input[type='tel'],
.sasta-form.profile-from .mask-input-container input,
.profile-from.profile-from input[type='text'],
.profile-from.profile-from input[type='email'],
.profile-from.profile-from input[type='password'],
.profile-from.profile-from input[type='tel'],
.profile-from.profile-from .mask-input-container input {
  height: 34px;
  line-height: 22px;
  margin: 0 0 10px;
  padding: 5px 10px; }
  @media screen and (max-width: 767px) {
    .sasta-form.profile-from input[type='text'],
    .sasta-form.profile-from input[type='email'],
    .sasta-form.profile-from input[type='password'],
    .sasta-form.profile-from input[type='tel'],
    .sasta-form.profile-from .mask-input-container input,
    .profile-from.profile-from input[type='text'],
    .profile-from.profile-from input[type='email'],
    .profile-from.profile-from input[type='password'],
    .profile-from.profile-from input[type='tel'],
    .profile-from.profile-from .mask-input-container input {
      height: 30px;
      padding: 3px 10px; } }

.sasta-form.profile-from .rrui__select,
.profile-from.profile-from .rrui__select {
  height: 34px; }
  @media screen and (max-width: 767px) {
    .sasta-form.profile-from .rrui__select,
    .profile-from.profile-from .rrui__select {
      height: 29px; } }

.sasta-form.profile-from .form-row.three-cols,
.profile-from.profile-from .form-row.three-cols {
  margin: 0 -7px; }
  @media screen and (max-width: 767px) {
    .sasta-form.profile-from .form-row.three-cols,
    .profile-from.profile-from .form-row.three-cols {
      margin: 0; } }
  .sasta-form.profile-from .form-row.three-cols .col,
  .profile-from.profile-from .form-row.three-cols .col {
    float: left;
    padding: 0 7px;
    width: 33.33%; }
    @media screen and (max-width: 767px) {
      .sasta-form.profile-from .form-row.three-cols .col,
      .profile-from.profile-from .form-row.three-cols .col {
        float: none;
        padding: 0;
        width: auto; } }
    .sasta-form.profile-from .form-row.three-cols .col .hint-message,
    .profile-from.profile-from .form-row.three-cols .col .hint-message {
      margin: 0; }

.sasta-form.profile-from .Select.sasta-dropdrown,
.profile-from.profile-from .Select.sasta-dropdrown {
  margin: 0 0 10px; }

.sasta-form.profile-from .react-select__control,
.profile-from.profile-from .react-select__control {
  height: 34px;
  margin: 0; }
  @media screen and (max-width: 767px) {
    .sasta-form.profile-from .react-select__control,
    .profile-from.profile-from .react-select__control {
      height: 30px; } }
  .sasta-form.profile-from .react-select__control .react-select__input,
  .profile-from.profile-from .react-select__control .react-select__input {
    height: 32px; }
    @media screen and (max-width: 767px) {
      .sasta-form.profile-from .react-select__control .react-select__input,
      .profile-from.profile-from .react-select__control .react-select__input {
        height: 28px; } }
    .sasta-form.profile-from .react-select__control .react-select__input > input,
    .profile-from.profile-from .react-select__control .react-select__input > input {
      padding: 6px 5px; }

.sasta-form.profile-from .react-select__placeholder,
.sasta-form.profile-from .react-select__value-container > .react-select__single-value,
.profile-from.profile-from .react-select__placeholder,
.profile-from.profile-from .react-select__value-container > .react-select__single-value {
  line-height: 32px; }

.sasta-form.profile-from .react-tel-input input[type='tel'],
.sasta-form.profile-from .react-tel-input input[type='text'],
.profile-from.profile-from .react-tel-input input[type='tel'],
.profile-from.profile-from .react-tel-input input[type='text'] {
  border-radius: 0 !important;
  height: 34px !important;
  line-height: 22px !important;
  margin-bottom: 10px !important;
  padding-left: 60px; }
  @media screen and (max-width: 767px) {
    .sasta-form.profile-from .react-tel-input input[type='tel'],
    .sasta-form.profile-from .react-tel-input input[type='text'],
    .profile-from.profile-from .react-tel-input input[type='tel'],
    .profile-from.profile-from .react-tel-input input[type='text'] {
      padding-left: 40px; } }

.sasta-form.profile-from .react-tel-input .selected-flag,
.profile-from.profile-from .react-tel-input .selected-flag {
  height: 32px !important; }

.image-holder {
  object-fit: cover; }

html.async-hide {
  opacity: 1 !important; }
