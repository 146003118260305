/* Viewport Sizes */
/* Rotation */
/* COLORS */
/* FONTS */
/* Font Sizes for Hotels */
/* Font Colors for Hotels */
/* Font weight */
/* mixin that let you change placeholder font color. */
.block-error {
  background: #ffffff;
  color: #626473;
  text-align: center; }
  .block-error.detail-item {
    border: 1px solid #d0d2d9; }
  .block-error .title {
    text-transform: uppercase; }
  .block-error .hint-message {
    font-style: italic; }

.page-failed {
  background: #f6f6f6;
  padding: 50px 0; }
