/* Viewport Sizes */
/* Rotation */
/* COLORS */
/* FONTS */
/* Font Sizes for Hotels */
/* Font Colors for Hotels */
/* Font weight */
/* mixin that let you change placeholder font color. */
.disclaimered {
  padding-top: 0; }
  .disclaimered .disclaimer {
    width: 100%;
    background: #f6ac30;
    padding: 8px;
    top: 0;
    font-family: 'Roboto';
    color: #ffffff;
    text-align: center;
    font-size: 12px;
    position: fixed;
    z-index: 98; }
    @media screen and (max-width: 1010px) and (min-width: 768px) {
      .disclaimered .disclaimer {
        font-size: 10px; } }
    .disclaimered .disclaimer .cross-button {
      float: right;
      background-image: url("../../../../../static/images/cross.png");
      background-size: cover;
      margin: 2px 0;
      padding: 5px;
      width: 12px;
      height: 12px; }
      @media screen and (max-width: 767px) {
        .disclaimered .disclaimer .cross-button {
          margin: 0; } }
    @media screen and (max-width: 767px) {
      .disclaimered .disclaimer {
        padding-left: 20px;
        padding-right: 20px;
        display: block;
        position: fixed;
        z-index: 1000; } }
    @media screen and (min-width: 768px) {
      .disclaimered .disclaimer {
        padding-left: 30px;
        padding-right: 30px; } }
  @media screen and (min-width: 768px) {
    .disclaimered div.multi-header-steps {
      margin-top: 30px; } }
  @media screen and (max-width: 767px) {
    .disclaimered .flight-panel {
      padding-top: 60px; } }
  @media screen and (min-width: 1010px) {
    .disclaimered .flight-panel {
      padding-top: 30px; } }
  @media screen and (min-width: 425px) and (max-width: 425px) {
    .disclaimered .header {
      margin-top: 68px; } }
  @media screen and (max-width: 767px) {
    .disclaimered .header {
      margin-top: 70px; } }
  @media screen and (min-width: 768px) {
    .disclaimered .header {
      margin-top: 30px; } }
  @media screen and (max-width: 767px) {
    .disclaimered header.transparent {
      margin-top: 0; } }
  @media screen and (min-width: 768px) {
    .disclaimered ul.account-nav {
      padding-top: 30px; } }
  @media screen and (min-width: 768px) {
    .disclaimered div.booking-header {
      margin-top: 40px; } }
  @media screen and (max-width: 767px) {
    .disclaimered div.booking-header {
      margin-top: 0; } }
  .disclaimered div.admin-filter-contatiner {
    margin-top: 50px; }
  .disclaimered div.admin-summary-container {
    margin-top: 30px; }
  .disclaimered div.quick-links-holder {
    padding-top: 80px; }
