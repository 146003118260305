/* Viewport Sizes */
/* Rotation */
/* COLORS */
/* FONTS */
/* Font Sizes for Hotels */
/* Font Colors for Hotels */
/* Font weight */
/* mixin that let you change placeholder font color. */
.sasta-animation-loader {
  position: relative;
  height: 30px;
  max-width: 500px;
  margin: 0 auto 15px; }
  .sasta-animation-loader div {
    width: 30px;
    height: 30px;
    background-color: #f6ac31;
    border-radius: 50%;
    position: absolute;
    left: -30px;
    animation: movertl 2100ms infinite cubic-bezier(0.255, 0.775, 0.81, 0.23); }
    .sasta-animation-loader div:nth-child(2) {
      animation-delay: 700ms !important; }
    .sasta-animation-loader div:nth-child(3) {
      animation-delay: 1400ms !important; }

@keyframes movertl {
  0% {
    left: -30px; }
  100% {
    left: 100%; } }
