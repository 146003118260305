/* Viewport Sizes */
/* Rotation */
/* COLORS */
/* FONTS */
/* Font Sizes for Hotels */
/* Font Colors for Hotels */
/* Font weight */
/* mixin that let you change placeholder font color. */
@media (min-width: 220px) and (max-width: 767px) {
  .container .pg_load_animation {
    height: 87.5vh; } }

.result-header + .pg_load_animation {
  height: calc(90vh - 200px);
  margin-top: 20px; }

.pg_load_animation {
  align-items: center;
  color: #818181;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  justify-content: center;
  line-height: 16px;
  text-align: center;
  height: 90vh; }
  .pg_load_animation h4 {
    color: #000000;
    font: 18px/20px "Montserrat", sans-serif !important;
    margin: 0 0 15px !important; }
  .pg_load_animation h3 {
    color: #818181;
    font: 19px/21px "Montserrat", sans-serif !important;
    margin: 0 0 15px !important; }
  .pg_load_animation .sasta-animation-loader {
    width: 100%; }
