/* Viewport Sizes */
/* Rotation */
/* COLORS */
/* FONTS */
/* Font Sizes for Hotels */
/* Font Colors for Hotels */
/* Font weight */
/* mixin that let you change placeholder font color. */
.verification-banner {
  text-align: center;
  background: #ffc107;
  margin: 0;
  padding: 20px 34px;
  font: 17px/16px "Roboto", sans-serif; }
  @media screen and (max-width: 767px) {
    .verification-banner {
      font: 15px/16px "Roboto", sans-serif; } }
  .verification-banner a {
    color: #006fae;
    cursor: pointer; }
    @media screen and (min-width: 1010px) {
      .verification-banner a {
        text-decoration: none; } }
